.scalingContainer {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border:1px solid purple; */
}

.boxContainer {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: bold;
  font-size: 1.5rem;
  /* border: 1px solid grey; */
  transform-origin: center;
}

.box {
  transform-style: preserve-3d;
  /* border: 1px solid red; */
}

.wrappingPaper {
  position: absolute;
  transform-origin: top center;
  border-style: dashed;
  border-color: lightgrey;
  pointer-events: none;
}

.face {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 8px solid black;
  cursor: grab;
  touch-action: none;
  transform-style: preserve-3d;
  background-color: var(--BG);
  /* background-color: transparent; */
  outline: 1px solid black;
  border-radius: 1px;
}

.left {
  border-right: 4px solid black;
  border-top: 4px solid black;
}
.right {
  border-left: 4px solid black;
  border-top: 4px solid black;
}
.top {
  border-top: 4px solid black;
  border-right: 4px solid black;
}

.arrow {
  fill: var(--ACCENT);
  flex-shrink: 0;
}

.arrowUp {
  transform: rotateX(90deg) translateY(-50%);
}
.arrowDown {
  transform: rotateX(-90deg) translateY(50%);
}
.arrowUpToLeft {
  transform: rotateY(90deg) rotateZ(90deg) translateY(50%);
}
.arrowUpToRight {
  transform: rotateY(90deg) rotateZ(90deg) translateY(50%);
}
.arrowDownToLeft {
  transform: rotateY(90deg) rotateZ(-90deg) translateY(-50%);
}
.arrowDownToRight {
  transform: rotateY(90deg) rotateZ(-90deg) translateY(-50%);
}

@media (max-width: 500px) {
  .scalingContainer {
    transform: scale(0.75) translateX(24px);
  }
}

@media (max-width: 350px) {
  .scalingContainer {
    transform: scale(0.5) translateX(32px);
  }
}
