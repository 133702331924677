.instruction {
  padding: 5rem 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  width: 100%;
}

.transformContainer {
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(-45deg);
  width: 120px;
  height: 90px;
}

.boxFace {
  border: 8px solid var(--ACCENT);
  box-sizing: border-box;
  position: absolute;
  background-color: var(--BG2);
  transform-origin: top center;
}

.paper {
  box-sizing: border-box;
  position: absolute;
  background-color: var(--BG2);
  border: 8px solid black;
  transform-origin: top center;
}

.leftFace {
  width: 120px;
  height: 90px;
  transform: translateZ(75px);
  border-right: 4px solid var(--ACCENT);
  border-top: 4px solid var(--ACCENT);
}

.rightFace {
  width: 150px;
  height: 90px;
  transform: translateX(45px) rotateY(90deg);
  border-left: 4px solid var(--ACCENT);
  border-top: 4px solid var(--ACCENT);
}

.topFace {
  width: 120px;
  height: 150px;
  transform: translateZ(75px) rotateX(-90deg);
  border-top: 4px solid var(--ACCENT);
  border-right: 4px solid var(--ACCENT);
}

.paper.nearTop {
  width: 210px;
  height: 75px;
  border-top: 4px solid black;
  transform: rotateX(-90deg) translateY(-75px) translateX(-45px);
}

.step1.paper {
  width: 210px;
  height: 480px;
  transform: rotateX(-90deg) translateY(-240px) translateX(-45px)
    translateZ(90px);
}

.step2.paper.bottom {
  width: 210px;
  height: 315px;
  transform: rotateX(-90deg) translateY(-75px) translateX(-45px)
    translateZ(90px);
}

.step2.paper.side {
  width: 210px;
  height: 90px;
  border-top: 4px solid black;
  transform: translateZ(75px) translateX(-45px);
}

.step2.paper.top {
  width: 210px;
  height: 75px;
  border-top: 4px solid black;
  transform: rotateX(-90deg) translateY(-75px) translateX(-45px);
}

.step3.rightFace {
  border-top: none;
}

.step3.paper.bottom {
  width: 210px;
  height: 150px;
  border-bottom: 4px solid black;
  transform: rotateX(-90deg) translateY(-75px) translateX(-45px)
    translateZ(90px);
}

.step3.paper.nearSide {
  width: 210px;
  height: 90px;
  border-top: 4px solid black;
  transform: translateZ(75px) translateX(-45px);
}

.step3.paper.farSide {
  width: 210px;
  height: 90px;
  border-bottom: 4px solid black;
  transform: translateZ(-75px) translateX(-45px);
}

.step3.paper.nearTop {
  border-bottom: 4px solid black;
}

.step3.paper.farTop {
  width: 210px;
  height: 75px;
  border-top: 4px solid black;
  transform: rotateX(-90deg) translateX(-45px);
}

.step4.paper.bottom {
  width: 120px;
  height: 150px;
  border-bottom: 4px solid black;
  transform: rotateX(-90deg) translateY(-75px) translateZ(90px);
}

.step4.paper.side {
  width: 120px;
  height: 90px;
  border: 8px solid black;
  border-top: 4px solid black;
  border-right: 4px solid black;
  transform: translateZ(75px);
}

.step4.paper.nearFoldedSide {
  width: 45px;
  height: 90px;
  border-top: 4px solid black;
  border-bottom: 2px solid black;
  border-left: 4px solid black;
  transform-origin: top left;
  transform: translateX(120px) translateZ(75px) rotateY(90deg);
}

.step4.paper.farFoldedSide {
  width: 45px;
  height: 90px;
  border-top: 4px solid black;
  border-bottom: 2px solid black;
  transform-origin: top left;
  transform: translateX(120px) translateZ(-75px) rotateY(-90deg);
}

.step4.nearFoldedTop {
  width: 120px;
  height: 75px;
  border: 4px solid black;
  border-left: none;
  border-right: none;
  transform: rotateX(-90deg) translateY(-75px);
}

.step4.farFoldedTop {
  width: 120px;
  height: 75px;
  border-right: none;
  border-top: 4px solid black;
  border-left: none;
  transform: rotateX(-90deg);
}

.svgFold {
  backface-visibility: shown;
  position: absolute;
  left: 0;
  stroke: black;
  fill: var(--BG2);
  stroke-width: 8px;
  width: 150px;
  height: 45px;
  transform-origin: top center;
}

.step4.fold1 {
  transform: translateX(45px) rotateY(90deg) rotateX(90deg);
}

.step4.fold2 {
  transform: translateX(45px) rotateY(90deg) rotateX(90deg) translateZ(-90px);
}

.step4.fold3 {
  transform: translateX(-75px) rotateY(90deg) rotateX(-90deg);
}

.step5Box .instructionRightFace {
  border-top: 8px solid var(--ACCENT);
}

.step5.paper.bottom {
  width: 120px;
  height: 150px;
  border: 8px solid black;
  border-bottom: 4px solid black;
  transform: rotateX(-90deg) translateY(-75px) translateZ(90px);
}

.step5.paper.side {
  width: 120px;
  height: 90px;
  border: 8px solid black;
  border-top: 4px solid black;
  border-right: 4px solid black;
  transform: translateZ(75px);
}

.step5.paper.nearFoldedSide {
  width: 45px;
  height: 90px;
  border-top: 4px solid black;
  border-left: 4px solid black;
  border-right: 8px solid var(--BG2);
  transform-origin: top left;
  transform: translateX(120px) translateZ(75px) rotateY(90deg);
}

.step5.paper.farFoldedSide {
  width: 45px;
  height: 90px;
  border-top: 4px solid black;
  border-right: 8px solid var(--BG2);
  transform-origin: top left;
  transform: translateX(120px) translateZ(-75px) rotateY(-90deg);
}

.step5.paper.nearFoldedTop {
  width: 120px;
  height: 75px;
  border: 4px solid black;
  border-left: 8px solid black;
  border-right: none;
  transform: rotateX(-90deg) translateY(-75px);
}

.step5.paper.farFoldedTop {
  width: 120px;
  height: 75px;
  border: 4px solid black;
  border-bottom: 8px solid black;
  border-left: 8px solid black;
  border-right: none;
  transform: rotateX(-90deg);
}

.step5.fold1 {
  stroke-width: 6px;
  transform: translateX(46px) rotateY(90deg) translateX(-1px) translateY(-2px)
    scaleY(1.15);
}

.step5.fold2 {
  stroke-width: 6px;
  transform: translateX(46px) rotateY(90deg) rotateX(180deg) translateX(-1px)
    translateY(-90px) scaleY(1.1);
}

@media (max-width: 450px) {
  .instruction {
    transform: scale(0.75);
  }
}

@media (max-width: 300px) {
  .instruction {
    transform: scale(0.5);
  }
}
