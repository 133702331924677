.boxMeasurement {
  text-align: center;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.boxMeasurementOutline {
  outline: 1px solid transparent;
  border-radius: 5px;
  padding: 5px;
}

.boxMeasurementOutline:hover {
  outline: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}

.editing {
  text-align: center;
  outline: 2px solid black;
  border-radius: 5px;
  font-weight: bold;
  cursor: text;
  width: 4ch;
}

.boxWidth,
.boxLength {
  transform-origin: top center;
}
.boxHeight {
  transform-origin: center right;
}

.units {
  display: inline;
  padding-left: 10px;
  font-size: 1.15rem;
}

@media (max-width: 500px) {
  .scalingWrap {
    transform: scale(1.33);
  }
  .wrapLength {
    padding-left: 1rem;
    padding-top: 1rem;
  }
  .wrapWidth {
    padding-right: 1rem;
    padding-top: 1rem;
  }
  .wrapHeight {
    padding-right: 1rem;
  }
}

@media (max-width: 350px) {
  .scalingWrap {
    transform: scale(2);
  }
  .wrapLength {
    padding-left: 2rem;
    padding-top: 2rem;
  }
  .wrapWidth {
    padding-right: 2rem;
    padding-top: 2rem;
  }
  .wrapHeight {
    padding-right: 3rem;
  }
}
