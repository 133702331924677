.calculator {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxAndForm {
  box-sizing: border-box;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: inset 2px 3px 12px rgba(0, 0, 0, 0.1),
    inset -1px -2px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem 1rem 3rem 1rem;
  overflow: hidden;
}

.boxFrame {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
}

.results {
  border-radius: 15px;
  border: 3px solid black;
  margin: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 375px;
}

.paperForm {
  padding-top: 1rem;
  max-width: 582px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 2;
}

#overlap {
  width: 4ch;
  padding: 0.5rem;
  padding-right: 0.25rem;
  border-radius: 5px;
  text-align: right;
}

#overlap:focus-within,
#overlapUnits:focus-within {
  outline: none;
}

.combinedInput {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 10ch;
  background-color: white;
  border-radius: 5px;
  border: 1px solid black;
  margin-top: 0.5rem;
}

.combinedInput:focus-within {
  outline: 2px solid black;
}

.overlapInput {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: bold;
}

.overlapInput select,
.overlapInput input {
  background-color: transparent;
  border: none;
}

#overlapUnits {
  text-align: center;
}

#units {
  margin-top: 0.5rem;
  border: 1px solid black;
  background-color: white;
}

#units:focus-within {
  outline: 2px solid black;
}

.unitsInput {
  text-align: left;
  font-weight: bold;
}

.unitsInput select {
  width: 7ch;
  padding: 0.5rem;
  border-radius: 5px;
}

.unitsInput label {
  display: block;
}

.percentInfo {
  color: dimgrey;
  padding-top: 1rem;
}

.percentInfo dl {
  padding: 0;
  margin: 0;
  text-align: center;
}

.percentInfo dd {
  padding: 0.25rem;
  font-size: 0.9rem;
  text-align: center;
}

.results {
  box-shadow: 2px 3px 12px lightgrey;
}

.results h2 {
  padding-bottom: 0.5rem;
  border-bottom: 3px solid var(--ACCENT);
}
.results dt {
  color: dimgrey;
  display: inline-block;
}
.results dd {
  font-size: 1.5rem;
  display: inline-block;
}

.results dd.overlapBreakdown {
  display: block;
  font-size: 1rem;
  text-align: right;
  color: dimgrey;
}

.roundingToggle {
  display: flex;
  justify-content: center;
  align-items: center;
}

#rounding {
  margin-left: 0.5rem;
}

@media (max-width: 800px) {
  .boxAndForm {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .paperForm {
    width: 90%;
  }
}

@media (max-width: 300px) {
  .paperForm {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .overlapInput {
    align-items: flex-start;
    padding-top: 1rem;
  }
}
