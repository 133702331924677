.perceivedDimensionsContainer {
  position: absolute;
}

.perceivedWidthContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.perceivedWidth {
  height: 1px;
  box-sizing: border-box;
}

.perceivedHeightContainer {
  position: absolute;
  display: flex;
  justify-content: center;
}

.perceivedHeight {
  box-sizing: border-box;
}
