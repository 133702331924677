:root {
  --BG: #f7f7f7;
  --BG2: #eeeeee;
  --ACCENT: lightpink;
}

@font-face {
  font-family: "Nunito";
  src: url("/src/assets/fonts/Nunito.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
}

.App {
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

body {
  background-color: var(--BG);
}

header {
  width: 100%;
}

.dragging,
.dragging * {
  cursor: grabbing;
}

.italic {
  font-style: italic;
}

.accent {
  color: dimgrey;
}

.accentParagraph {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1);
  padding: 1rem 0 2rem;
  margin: 0;
}

.App ul {
  padding: 3rem 1rem 2rem;
  list-style: none;
  margin: 0;
}

.App li {
  text-align: left;
  padding: 0.5rem 1.5rem;
  max-width: 80ch;
}

.App h1 {
  font-family: "Nunito", sans-serif;
  font-weight: bolder;
  box-sizing: border-box;
  text-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25),
    -2px -3px 6px rgba(255, 255, 255, 0.2);
  width: 100%;
  margin: 0;
  padding: 3rem 1rem;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.2rem;
  background-color: var(--ACCENT);
  background-image: linear-gradient(to bottom right, lavender, lightpink);
  color: rgba(0, 0, 0, 0.75);
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
