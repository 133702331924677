.slideReel {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--BG2);
  padding: 3rem 0;
  box-shadow: inset 2px 3px 10px lightgrey;
}

.slideReel h2 {
  font-size: 1.75rem;
}

.slideViewport {
  box-sizing: border-box;
  overflow: hidden;
  width: 90%;
  padding: 4rem 0;
  margin: 2rem 0;
  border-left: none;
  border-right: none;
  border-radius: 15px;
  box-shadow: inset 2px 3px 12px rgba(0, 0, 0, 0.1),
    inset -1px -2px 6px rgba(0, 0, 0, 0.1);
}

.slides {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  transition: margin-left 0.3s ease;
  cursor: grab;
  touch-action: none;
}

.slides.dragging {
  transition: none 0s ease;
  cursor: grabbing;
}

.slide {
  list-style-type: none;
  box-sizing: border-box;
  margin: 0;
  flex-shrink: 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/* 
.slideText {
  font-size: 1.15rem;
  line-height: 1.25;
  letter-spacing: 1.5px;
  pointer-events: none;
  margin-bottom: 0;
} */

.slideControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 0 2rem;
}

.slideControlArrows {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.slideControlArrow {
  cursor: pointer;
  padding: 0.5rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px solid black;
  background-color: var(--BG2);
}

.slideControlArrow .icon {
  stroke: black;
  stroke-width: 32px;
  width: 1rem;
  height: 1rem;
  padding: 0;
  margin: 0;
  overflow: visible;
}

.slideControlArrow:hover {
  background-color: var(--BG);
}

.slideControlArrow:hover .icon {
  stroke: var(--ACCENT);
}

.slideControlArrow:active .icon {
  stroke: grey;
}

.slideControlDots {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 104px;
}

.slideControlDot {
  cursor: pointer;
  padding: 0;
  margin: 0 4px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: black;
  border: none;
  transition: width 0.33s ease-out;
}

.slideControlDot:hover {
  background-color: var(--ACCENT);
}
.slideControlDot:active {
  background-color: grey;
}

.slideControlDot.active {
  width: 32px;
  border-radius: 4px;
  border: none;
  background-color: var(--ACCENT);
  transition: width 0.33s ease-out;
}

@media (max-width: 800px) {
  .slideReel {
    min-height: 100vh;
  }
  .slideViewport {
    width: 100%;
  }
}
