.ruler {
  border-color: var(--ACCENT);
  position: absolute;
  display: flex;
}

.rulerWidth,
.rulerLength {
  border-top-width: 6px;
  border-top-style: solid;
  padding-top: 5%;
  margin-top: 5%;
  justify-content: center;
}

.rulerHeight {
  bottom: 0;
  right: 0;
  border-right-width: 6px;
  border-right-style: solid;
  padding-right: 5%;
  margin-right: 5%;
  justify-content: flex-end;
  align-items: center;
}
